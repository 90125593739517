import axios from 'axios'
// import store from '@/store'
import { message } from 'ant-design-vue';
import { getToken } from '@/utils/auth'
// 根据环境不同引入不同api地址
// import { baseApi } from '@/config'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,//baseApi, // url = base api url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request拦截器 request interceptor
service.interceptors.request.use(
  config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // 默认关闭loading弹窗
    if (config.loading) {
      message.info("加载中")
    }
    // if (store.getters.token) {
    //   config.headers['token'] = store.getters.token
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // 请求失败
    if (res.code && res.code !== 200) {
      message.error(res.msg);
      return Promise.reject(res || 'error')
    } else { // 成功
      return Promise.resolve(res)
    }
  },
  error => {
    //Toast.clear();
    message.error("服务器开小差啦")
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
