import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import getters from './getters'
import user from './modules/user'
// import setting from './modules/settings'

const store = new Vuex.Store({
  modules: {
    user,
    // setting
  },
  getters
})

export default store
