//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndexNav from '@/views/components/IndexNav'
import UserAvatar from "./userAvatar"
export default {
  name: "userInfoIndex",
  components: {IndexNav, UserAvatar},
  data() {
    return {
      baseForm: {},
      passwordForm: {},
    }
  },
  methods: {
  }
}
