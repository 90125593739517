//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndexNav from '@/views/components/IndexNav'
import { oneRandomQuestion, checkAnswer } from '@/api/question';
export default {
  name: "trainIndex",
  components: { IndexNav },
  data() {
    return {
      trainObject: 0,
      radioStyle: {
        display: 'block',
        height: '35px',
        lineHeight: '35px',
      },
      questionDetail: {
        questionId: 0,
        type: 0,
        content: '',
        optionList: [
          {
            answerOptionId: 0,
            content: '',
            sort: 0
          },
        ],
      },
      answerOptionIdList: [],
      singleAnswerOptionId: 0,
      buttonStatus: 0,
      loading: false,
      correctStatus: true,
      percent: 0,
      autoNext: false,
      loadingQuestion: false
    }
  },
  created() {
    this.trainObject = this.$route.params.trainObject ?? 0;
    this.getOneRandomQuestion();
  },
  methods: {
    getOneRandomQuestion() {
      this.loadingQuestion = true;
      return oneRandomQuestion(this.trainObject).then(res => {
        this.loadingQuestion = false;
        this.questionDetail = res.data
        console.log(res.data)
        return Promise.resolve();
      })
    },
    init() {
      this.answerOptionIdList = []
      this.singleAnswerOptionId = 0;
      this.correctStatus = 0;
      this.percent = 0;
    },
    onChangeAutoNext(e) {
      this.autoNext = e.target.checked;
    },
    nextQuestion() {
      this.getOneRandomQuestion().then(() => {
        this.init();
      });
    },
    checkAnswerHandler() {
      let waitPromise;
      this.loading = true;
      if(this.questionDetail.type === 1 || this.questionDetail.type === 2) {
        waitPromise = checkAnswer(this.questionDetail.questionId, [this.singleAnswerOptionId])
      } else {
        waitPromise = checkAnswer(this.questionDetail.questionId, this.answerOptionIdList)
      }
      waitPromise.then(res => {
        this.loading = false;
        // 答案正确
        if(res.data === true) {
          this.correctStatus = 1;
          // 跳下一题
          if(this.autoNext === true) {
            let timer = setInterval(() => {
              this.percent += 10;
              if(this.percent >= 100) {
                clearInterval(timer);
                this.getOneRandomQuestion().then(() => {
                  this.init();
                });
              }
            }, 100);
          }
        } else if(res.data === false) {
          this.correctStatus = 2;
        }
        console.log(res)
      })
    }
  },
  computed: {
    typeName() {
      if(this.questionDetail.type === 1) {
        return '单选'
      } else if(this.questionDetail.type === 2) {
        return '判断'
      } else if(this.questionDetail.type === 3) {
        return '多选'
      }
    }
  }


}
