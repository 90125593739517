import request from '@/utils/request'

// 用户培训数据
export function getStudentTrainData(applyId) {
  return request({
    url: `/psedu-base/student/studentTrainData/${applyId}`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}