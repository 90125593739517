import router from './router'
import store from './store'
import { getToken } from '@/utils/auth'
import { message } from "ant-design-vue";

const whiteList = ['/', '/index', '/test']

router.beforeEach((to, from, next) => {
  to.meta.title && (document.title = to.meta.title);
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => {
          next({ ...to, replace: true })
        }).catch(err => {
            store.dispatch('LogOut').then(() => {
              message.error(err)
              next({ path: '/' })
            })
          })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1 || to.path.includes("notice")) {
      // 在免登录白名单，直接进入
      next()
    } else {
      message.error('用户未登录')
      next(`/`) // 否则全部重定向到登录页
    }
  }
})

router.afterEach(() => {
})
