import Vue from 'vue'

import router from "./router";
import store from './store'
import './permission'

// ant design vue
import { Button, DatePicker, Menu, Row, Col, Dropdown, Icon } from 'ant-design-vue';
Vue.use(DatePicker);
Vue.use(Button);
Vue.use(Menu);
Vue.use(Row);
Vue.use(Col);
Vue.use(Dropdown);
Vue.use(Icon);
import { List, Card } from 'ant-design-vue';
Vue.use(List);
Vue.use(Card);
import { FormModel, Input } from 'ant-design-vue';
Vue.use(FormModel);
Vue.use(Input);
import { Form, Avatar, Popconfirm, Select, Tag, Modal } from 'ant-design-vue';
Vue.use(Form);
Vue.use(Avatar);
Vue.use(Popconfirm);
Vue.use(Select);
Vue.use(Tag);
Vue.use(Modal);
import { Spin, Radio, Checkbox, Space, Table, Progress, Divider, Tabs, Upload, Descriptions } from 'ant-design-vue';
Vue.use(Spin);
Vue.use(Radio);
Vue.use(Checkbox)
Vue.use(Space);
Vue.use(Table);
Vue.use(Progress);
Vue.use(Divider);
Vue.use(Tabs);
Vue.use(Upload);
Vue.use(Descriptions);


import VueCropper from "vue-cropper";
Vue.use(VueCropper)

import { message } from 'ant-design-vue'
Vue.prototype.$message = message;

// 轮播
// import VuePerSliders from "./components/VuePerSliders";
// Vue.use(VuePerSliders);

import App from './App.vue'


// Vue.component("aButton", Button);


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
