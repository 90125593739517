import request from '@/utils/request'
// 登录方法
export function getCanSignSemester() {
  return request({
    url: '/psedu-base/semester/canSignSemester',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}
