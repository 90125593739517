//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndexNav from '@/views/components/IndexNav'
import { getExamDetail } from '../../api/exam'
const columns = [
  {
    title: '考试编号',
    dataIndex: 'examId',
    key: 'examId'
  },
  {
    title: '考试名称',
    dataIndex: 'examName',
    key: 'examName',
  },
  {
    title: '考试时长',
    dataIndex: 'totalTime',
    key: 'totalTime'
  },
  {
    title: '允许考试次数',
    dataIndex: 'maxRepeatCount',
    key: 'maxRepeatCount'
  }
];
export default {
  name: "ExamIndex",
  components: { IndexNav },
  data() {
    return {
      loadingExamList: false,
      trainObject: 0,
      columns,
      examList: [
      ]
    }
  },
  created() {
    this.getExamDetailList()
  },
  methods: {
    getExamDetailList() {
      this.loadingExamList = true;
      getExamDetail().then(res => {
        this.examList = res.data;
        this.loadingExamList = false;
        console.log(this.examList)
      })
    }
  }
}
