//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { submitApply, getApplyDetail } from '@/api/apply'
let formData = {
  applyId: null,
  realName: null,
  semeId: null,
  hnusterId: null,
  sex: null,
  nation: null,
  nativePlace: null,
  birthday: '2000-01-01',
  submitPaperDate: '2021-01-01',
  partyData: null,
  eduBackground: null,
  classes: null,
  phone: null,
  email: null,
}
export default {
  name: "ApplyAddOrUpdate",
  data() {
    return {
      spinning: false,
      locale,
      applyModalVisible: false,
      form: Object.assign({}, formData),
      confirmLoading: false,
      rules: {
        realName: [
          { required: true, message: '请输入真实名称', trigger: 'blur' },
        ],
        hnusterId: [
          { required: true, message: '请输入学号/工号', trigger: 'blur' },
        ],
        sex: [
          { required: true, message: '请输入性别', trigger: 'blur' },
        ],
        nation: [
          { required: true, message: '请输入民族', trigger: 'blur' },
        ],
        nativePlace: [
          { required: true, message: '请输入籍贯', trigger: 'blur' },
        ],
        birthday: [
          { required: true, message: '请输入生日', trigger: 'blur' },
        ],
        submitPaperDate: [
          { required: true, message: '', trigger: 'blur' },
        ],
        partyData: [
          { required: true, message: '请输入首次入党时间', trigger: 'blur' },
        ],
        eduBackground: [
          { required: true, message: '请输入学历', trigger: 'blur' },
        ],
        classes: [
          { required: true, message: '请输入班级', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
        ],
        email: null,
      }
    }
  },
  methods: {
    moment,
    init (semeId, applyId) {
      this.applyModalVisible = true;
      this.$nextTick(() => {
        this.form = Object.assign({}, formData);
        this.form.semeId = semeId;
        if(applyId !== undefined) {
          this.spinning = true;
          getApplyDetail(applyId).then(res => {
            this.form = res.data;
            this.spinning = false;
          })
        }
      })
    },
    submitApplyHandler() {
       this.$refs['applyForm'].validate(valid => {
         if(valid) {
           submitApply(this.form).then(res => {
             this.confirmLoading = false;
             this.applyModalVisible = false;
             this.$message.success(this.form.applyId == null? '添加成功' : '修改成功');
             this.$emit('reLoadApplyList');
           })
         }
       })
    },
  },
  created() {
  }
}
