import request from '@/utils/request'

// 获取学期课程
export function getSemesterCourse(semeId) {
  return request({
    url: `psedu-base/course/semeCourseInfo/${semeId}`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}