//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "liveIndex",
  data() {
    return {
      liveUrl: this.$route.query.liveUrl,
       player: {}
    }
  },
  mounted() {
    this.init ();
  },
  methods: {
    init () {
      var player = TCPlayer('player-container', {
      });
      this.player = player;
      var url = 'webrtc://onlive.mingyuefusu.top/live/' +this.liveUrl;
      player.src(url); // url 播放地址
    }
  },
  beforeRouteLeave(to, from, next) {
    this.player.dispose();
    console.log("已经删除");
    next();
  }
}
