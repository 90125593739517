//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "testIndex",
  data() {
    return {

    }
  },
  mounted() {
    this.init ();
  },
  methods: {
    init () {
      var player = TCPlayer('player-container-id', {
        width: 1200,
        height: 600
      }); // player-container-id 为播放器容器 ID，必须与 html 中一致
      var url = 'webrtc://onlive.mingyuefusu.top/live/test';
      player.src(url); // url 播放地址
    }
  }
}
