//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { login, getCodeImg, register } from "@/api/login";
import { getPsDeptList } from "@/api/optionSelect"

export default {
  name: 'LoginRegistCard',
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.registForm.checkPassword !== '') {
          this.$refs.registForm.validateField('checkPass');
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'));
      } else if (value !== this.registForm.password) {
        callback(new Error("两密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      codeUrl: "",
      // 验证码开关
      captchaOnOff: true,
      headStyle: {
        textAlign: "center"
      },
      loginRules: {
        username: [{required: true, message: '请输入账号', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}]
      },
      registRules: {
        nickName: [{required: true, message: '请输入真实姓名', trigger: 'blur'}],
        username: [{required: true, message: '请输入学号', trigger: 'blur'}],
        deptId: [{required: true, message: '请选择分党校', trigger: 'blur'}],
        checkPassword: [
          // {required: true, message: '请输入确认密码', trigger: 'blur'},
          {
            validator: validatePass2, trigger: 'change'
          }
        ],
        password: [
          // {required: true, message: '请输入密码', trigger: 'blur'},
          {
            validator: validatePass, trigger: 'change'
          }
        ],
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
          {required: true, message: '请输入邮箱', trigger: 'blur'},
        ],
      },
      loginForm: {
        username: "",
        password: "",
        code: "",
        uuid: ""
      },
      loadLogin: false,
      registForm: {
        nickName: "",
        username: "",
        password: "",
        checkPassword: "",
        deptId: 200,
        email: ""
      },
      deptList: [
        {deptId: '-1', deptName: '---请选择分党校---'},
        {deptId: '200', deptName: '计算机'},
        {deptId: '201', deptName: '土木工程学院'},
        {deptId: '202', deptName: '教育学院'},
        {deptId: '203', deptName: '建筑与艺术设计学院'}
      ],
      // 登录注册tab
      tabList: [
        {
          key: 'tab1',
          // tab: 'tab1',
          scopedSlots: { tab: 'customRender' },
        },
        {
          key: 'tab2',
          tab: 'tab2',
        },
      ],
      tabListNoTitle: [
        {
          key: 'login',
          tab: '学员登录',
        },
        {
          key: 'regist',
          tab: '学员注册',
        },
      ],
      key: 'login',
      noTitleKey: 'login',
    }
  },
  beforeCreate() {
  },
  mounted() {
    this.getCode();
    this.initDeptList();
  },
  methods: {
    getCode() {
      getCodeImg().then(res => {
        this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    userLogin() {
      console.log(this.loginForm.code);
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loadLogin = true;
          // login(this.loginForm.username, this.loginForm.password, this.loginForm.code, this.loginForm.uuid).then(res => {
          //   // 登录成功
          //   console.log(res);
          //   this.loadLogin = false;
          //
          // }).catch(err => {
          //   this.loadLogin = false;
          //   if (this.captchaOnOff) {
          //     this.getCode();
          //   }
          // })
          this.$store.dispatch("Login", this.loginForm).then(() => {
            this.loadLogin = false;
            this.$router.push({ path: '/user' }).catch(()=>{});
          }).catch(() => {
            this.loadLogin = false;
            if (this.captchaOnOff) {
              this.getCode();
            }
          });
        } else {
          this.loadLogin = false;
          return false;
        }
      });
    },
    userRegister() {
      this.$refs.registForm.validate(valid => {
            if (valid) {
              register(this.registForm).then(res => {
                console.log(res)
              }).catch(err => {
                console.log(err)
              })
            }
        }
      )
    },
    initDeptList() {
      getPsDeptList().then(res => {
        this.deptList = res.data;
      })
    },
    onTabChange(key, type) {
      console.log(key, type);
      this[type] = key;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
        }
      });
    },
  }
}
