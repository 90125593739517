import request from '@/utils/request'
// 随机一道题目
export function oneRandomQuestion(trainObject) {
  return request({
    url: `/psedu-exam/question/oneRandomQuestion/${trainObject}`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}
// 检查答案
export function checkAnswer(questionId, optionIdList) {
  return request({
    url: `/psedu-exam/question/checkAnswer`,
    headers: {
      isToken: true
    },
    data: {
      questionId,
      optionIdList
    },
    method: 'post'
  })
}
