//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "IndexNav",
  methods: {
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        location.href = "/index";
      })
    }
  }
}
