//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndexNav from '@/views/components/IndexNav'
import { getExamTestPaper, submitExamTestPaper } from '../../../api/exam'
import { Modal } from "ant-design-vue";
export default {
  name: "examTestIndex",
  components: {IndexNav},
  data() {
    return {
      trainObject: 0,
      loadingQuestion: false,
      singleAnswerList: [],
      judgeAnswerList: [],
      multiAnswerList: [],
      singleQuestionDetailList: [

      ],
      judgeQuestionDetailList: [

      ],
      multiQuestionDetailList: [

      ],
      radioStyle: {
        display: 'block',
        height: '35px',
        lineHeight: '35px',
      },
      submitResLoading: false
    }
  },
  created() {
    this.$nextTick(() => {
      this.checkCheat()
    })
      this.getExamTestPaperAndSave()
  },
  methods: {
    getExamTestPaperAndSave() {
      this.loadingQuestion = true;
      getExamTestPaper(this.trainObject).then(res => {
        let data = res.data;
        this.singleQuestionDetailList = data.singleQuestionDetailList;
        this.judgeQuestionDetailList = data.judgeQuestionDetailList;
        this.multiQuestionDetailList = data.multiQuestionDetailList;
        this.loadingQuestion = false;
      })
    },
    checkCheat() {
      //屏蔽右击
      document.body.oncontextmenu=document.body.ondragstart=document.body.onselectstart=document.body.onbeforecopy=function(){return false;};
      //屏蔽复制粘贴
      document.body.oncopy=document.body.oncut=function(){return false;};

      let count = 0;
      let blurListener = 1;
      document.addEventListener("visibilitychange", function() {
        if (document.hidden) {
          count++;
          Modal.info({content: "党校考试系统：\n请勿离开答题页面，离开一定次数和时间后将作作弊标记。#3"});
        } else {
          //alert("回到答题页面");
        }
      });

      window.addEventListener("blur", leave);
      function leave() {
        if (blurListener === 1){
          count++;
          Modal.info({content: "党校考试系统：\n请勿离开答题页面，离开一定次数和时间后将作作弊标记。#4"});
        }

      }

      var max_resolution = 0;

      function check_resolution() {
        // 有些手机可以分屏,没什么好的检测方法,现在是判断他的可视分辨率有没有变小来判断是不是使用了分屏
        var w=document.body.clientWidth;
        var h=document.body.clientHeight;
        var s = w*h;
        if (s>=max_resolution){
          max_resolution = (w-50)*(h-100);
        }else{
          count++;
          Modal.info({content: "党校考试系统：\n请全屏运行浏览器，一定次数和时间后将作作弊标记。#5\n" + s + ","+ max_resolution});
          if (count > 15){
            Modal.info({content: "党校考试系统：\n严重警告,你的疑似作弊动作和时间已超过一定限制,请诚信考试"});
          }
        }
      }
      var t1 = window.setInterval(check_resolution,1000);
    },
    typeName(type) {
      if(type === 1) {
        return '单选'
      } else if(type === 2) {
        return '判断'
      } else if(type === 3) {
        return '多选'
      }
    },
    submitPaper() {
      let resSinglePaperData = []
      let resJudgePaperData = []
      let resMultiPaperData = []
      let resPaperData = {
        'single': resSinglePaperData,
        'judge': resJudgePaperData,
        'multi': resMultiPaperData
      }
      // 单选答案
      for(let index = 0 ; index < this.singleQuestionDetailList.length; index++) {
        resSinglePaperData.push({
          questionId: this.singleQuestionDetailList[index].questionId,
          optionIdList: this.singleAnswerList[index] ? [this.singleAnswerList[index]] : []
        })
      }
      // 判断答案
      for(let index = 0 ; index < this.judgeQuestionDetailList.length; index++) {
        resJudgePaperData.push({
          questionId: this.judgeQuestionDetailList[index].questionId,
          optionIdList: this.judgeAnswerList[index] ? [this.judgeAnswerList[index]] : []
        })
      }
      // 多选答案
      for(let index = 0 ; index < this.multiQuestionDetailList.length; index++) {
        resMultiPaperData.push({
          questionId: this.multiQuestionDetailList[index].questionId,
          optionIdList: this.multiAnswerList[index] ?? []
        })
      }

      this.submitResLoading = true;
      submitExamTestPaper(resPaperData).then(res => {
        let data = res.data;
        Modal.info({
          content: `答题完成，你的成绩为 ${data.score} ，可以继续答题`
        });
        let singleResList = data.singleStatusList ?? [];
        let judgeResList = data.judgeStatusList ?? [];
        let multiResList = data.multiStatusList ?? [];
        // 判断回显
        judgeResList.forEach((answerStatus, index) => {
          console.log(answerStatus.answerCorrect)
          console.log(answerStatus.answerCorrect === false)
          this.$set(this.judgeQuestionDetailList[index], 'correctStatus', 0)
          if(answerStatus.answerCorrect === false) {
            this.$set(this.judgeQuestionDetailList[index], 'correctStatus', 2)
            console.log('status' +this.judgeQuestionDetailList[index].correctStatus)
          }
        })
        // 选择回显
        singleResList.forEach((answerStatus, index) => {
          this.$set(this.singleQuestionDetailList[index], 'correctStatus', 0)
          if(answerStatus.answerCorrect === false) {
            this.$set(this.singleQuestionDetailList[index], 'correctStatus', 2)
          }
        })
        // 多选回显
        multiResList.forEach((answerStatus, index) => {
          this.$set(this.multiQuestionDetailList[index], 'correctStatus', 0)
          if(answerStatus.answerCorrect === false) {
            this.$set(this.multiQuestionDetailList[index], 'correctStatus', 2)
          }
        })
        this.submitResLoading = false;
      }).catch(err => {
        Modal.info({
          content: '提交失败，请重新提交'
        });
        this.submitResLoading = false;
      })
      console.log(resPaperData);
    }
  }
}
