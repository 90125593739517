//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import LoginRegistCard from '@/components/login/LoginRegistCard'
import IndexNav from '@/views/components/IndexNav'
import IndexNotice from '@/views/components/IndexNotice'

export default {
  name: 'Index',
  components: { VueperSlides, VueperSlide, LoginRegistCard, IndexNav, IndexNotice },
  data() {
    return {
      // 轮播图
      slides: [
        {
          title: '发展对象培训',
          // content: '发展对象培训',
          image: require('@/assets/bg5.jpg')
        },
        {
          title: '入党积极分子培训',
          // content: '入党积极分子培训',
          image: require('@/assets/bg5.jpg')
        }
      ]
    };
  },
  methods: {
  },
  created() {
  }
}
