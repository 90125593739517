import request from '@/utils/request'

// 获取通知公告
export function getNotice() {
  return request({
    url: '/system/notice/list',
    headers: {
      isToken: false
    },
    method: 'get'
  })
}

// 获取通知公告详细
export function getNoticeDetail(noticeId) {
  return request({
    url: '/system/notice/' +noticeId,
    headers: {
      isToken: false
    },
    method: 'get'
  })
}