import request from '@/utils/request'

// 获取考试信息
export function getExamDetail() {
    return request({
        url: '/psedu-exam/exam/examDetail',
        headers: {
            isToken: true
        },
        method: 'get'
    })
}

// 自我测试
export function getExamTestPaper(trainObject) {
    return request({
        url: `/psedu-exam/exam/examTest/${trainObject}`,
        headers: {
            isToken: true
        },
        method: 'get'
    })
}

// 正式考试
export function getExamPaper(examDeptStatusId) {
    return request({
        url: `/psedu-exam/exam/enterExam/${examDeptStatusId}`,
        headers: {
            isToken: true
        },
        method: 'post'
    })
}

// 提交自我测试
export function submitExamTestPaper(examTestPaperAnswer) {
    return request({
        url: `/psedu-exam/exam/submitTestPaper`,
        headers: {
            isToken: true
        },
        data: examTestPaperAnswer,
        method: 'post'
    })
}

// 提交正式考试
export function submitExamPaper(examPaperAnswer) {
    return request({
        url: `/psedu-exam/exam/submitExamPaper`,
        headers: {
            isToken: true
        },
        data: examPaperAnswer,
        method: 'post'
    })
}