import request from '@/utils/request'

// 用户申请列表
export function getUserApply() {
  return request({
    url: '/psedu-base/apply/userApply',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

export function getApplyDetail(applyId) {
  return request({
    url: `/psedu-base/apply/detail/${applyId}`,
    headers: {
      isToken: true,
    },
    method: 'get'
  })
}

// 用户提交申请
export function submitApply(data) {
  return request({
    url: '/psedu-base/apply/submit',
    headers: {
      isToken: true
    },
    data: data,
    method: 'post'
  })
}

// 查看申请总结
export function getApplySummary(applyId) {
  return request({
    url: `/psedu-base/apply/summary/${applyId}`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}
