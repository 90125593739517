//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCanSignSemester } from '@/api/semester';
import { getUserApply, getApplySummary } from '@/api/apply'
import ApplyAddOrUpdate from './apply/ApplyAddOrUpdate'
import { getStudentTrainData } from '@/api/student'
import { getSemesterCourse } from "../../api/course";
import { studentSign } from "../../api/sign";

export default {
name: "UserIndex",
data() {
  const columns = [
    {
      title: '编号',
      dataIndex: 'courseId',
      key: 'courseId',
      width: 60
    },
    {
      title: '名称',
      dataIndex: 'courseTitle',
      key: 'courseTitle',
      width: 100
    },
    {
      title: '教师',
      dataIndex: 'teacher',
      key: 'teacher',
      width: 100
    },
    {
      title: '内容',
      dataIndex: 'content',
      key: 'content',
      width: 100
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
      key: 'startTime',
      width: 100
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      key: 'endTime',
      width: 100
    },
    {
      title: '操作',
      key: 'action',
      scopedSlots: {customRender: 'action'},
      width: 200
    }
  ];
  return {
    canSignSemesterList: [],
    userApplyList: [],
    applyTagColor: ["orange", "green", "red"],
    applyTagName: ["审核中", "审核通过", "审核未通过"],
    summaryModalVisible: false,
    summaryForm: {},
    loadingCanSignSemesterList: false,
    loadingUserApplyList: false,
    loadingStudentCourse: false,
    trainModalVisible: false,
    courseModalVisible: false,
    studentTrainData: {},
    loadingStudentTrainData: false,
    columns,
    courseList: [],
    loadingSign: false
  }
},
components: {ApplyAddOrUpdate},
methods: {
  logout() {
      this.$store.dispatch('LogOut').then(() => {
        location.href = "/index";
      })
  },
  getCanSignSemesterList() {
    this.loadingCanSignSemesterList = true;
    getCanSignSemester().then(res => {
      this.canSignSemesterList = res.data;
      this.loadingCanSignSemesterList = false;
    })
  },
  getUserApplyList() {
    this.loadingUserApplyList = true;
    getUserApply().then(res => {
      this.userApplyList = res.data;
      this.loadingUserApplyList = false;
    })
  },
  showSummaryModal(applyId) {
    this.summaryForm.applyId = applyId;
    this.getApplySummaryById(applyId);
    this.summaryModalVisible = true;
  },
  getApplySummaryById(applyId) {
    getApplySummary(applyId).then(res => {
      this.summaryForm.summary = res.data
    })
  },
  applyAddOrUpdateHandler(semeId, applyId) {
    this.$nextTick(() => {
      this.$refs['applyAddOrUpdate'].init(semeId, applyId)
    })
  },
  courseHandler(semeId) {
    this.loadingStudentCourse = true;
    this.courseModalVisible = true;
    getSemesterCourse(semeId).then(res => {
      this.courseList = res.data.courseSimpleVoList;
      this.loadingStudentCourse = false;
    })
  },
  trainDataHandler(applyId) {
    this.trainModalVisible = true;
    this.loadingStudentTrainData = true;
    getStudentTrainData(applyId).then(res => {
      this.studentTrainData = res.data;
      this.loadingStudentTrainData = false;
    })
  },
  toVideo(videoUrl) {
    window.open(videoUrl, "_blank");
  },
  studentSignHandler(signLaunchId) {
    this.loadingSign = true;
    var geolocation = new BMapGL.Geolocation();
    var that = this;
    geolocation.getCurrentPosition(function(r) {
      if (this.getStatus() == BMAP_STATUS_SUCCESS) {
        console.log('您的位置：' + r.point.lng + ',' + r.point.lat);
        studentSign(signLaunchId, {lng: r.point.lng, lat: r.point.lat}).then(res => {
          that.loadingSign = false;
          that.$message.success("签到成功");
          console.log(res);
        }).catch(err => {
          that.loadingSign = false;
          console.log(err)
        })
      }
      else {
        that.loadingSign = false;
      }
    });

  },
  urlsToUrlArr(urls) {
    if(!urls) {
      return [];
    }
    return urls.split(',');
  }

},
created() {
  this.getCanSignSemesterList();
  this.getUserApplyList();
}
}
