//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndexNav from '@/views/components/IndexNav'
import {getNoticeDetail} from '../../api/notice'
export default {
  name: "noticeIndex",
  components: {IndexNav},
  data() {
    return {
      noticeId: this.$route.params.noticeId,
      noticeData: {}
    }
  },
  created() {
    getNoticeDetail(this.noticeId).then(res => {
      this.noticeData = res.data;
    })
  }
}
