//
//
//
//
//
//
//
//
//
//
//
//
//

  import { getNotice } from '@/api/notice'
export default {
  name: "IndexNotice",
  data() {
    return {
      noticeList: [],
    }
  },
  created() {
    getNotice().then(res => {
      this.noticeList = res.rows;
      console.log(res);
    })
  }
}
