import request from '@/utils/request'

// 获取党校部门列表
export function getPsDeptList() {
  return request({
    url: '/system/dept/psDeptList',
    headers: {
      isToken: false
    },
    method: 'get'
  })
}
