import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

import Index from "../views/index";
import NoticeIndex from "../views/notice"
import Myself from "../views/myself"
import UserIndex from "../views/user"
import userInfoIndex from "../views/user/userInfo"
import ExamIndex from "../views/exam"
import TestIndex from "../views/test"
import TrainIndex from "../views/exam/train"
import RealExamIndex from "../views/exam/realExam"
import ExamTestIndex from "../views/exam/examTest"
import LiveIndex from "../views/live"

const routes = [
  {
    path: '/',
    redirect: "/index",
  },
  {
    path: '/index',
    component: Index,
    meta: { title: '首页-党校培训管理系统'}
  },
  {
    path: '/noticeDetail/:noticeId',
    component: NoticeIndex,
    meta: { title: '首页-党校培训管理系统'}
  },
  {
    path: '/myself',
    component: Myself,
    meta: { title: '个人信息'}
  },
  {
    path: '/user',
    component: UserIndex,
    meta: { title: '个人中心-党校培训管理系统'}
  },
  {
    path: '/userInfo',
    component: userInfoIndex,
    meta: { title: '个人设置-党校培训管理系统'}
  },
  {
    path: '/exam',
    component: ExamIndex,
    meta: { title: '考试系统-党校培训管理系统'},
  },
  {
    meta: { title: '题目练习-党校培训管理系统' },
    path: '/train/:trainObject',
    component: TrainIndex
  },
  {
    path: '/realExam/:examDeptStatusId',
    component: RealExamIndex,
    meta: { title: '正式考试'}
  },
  {
    path: '/examTest',
    component: ExamTestIndex,
    meta: { title: '自我测试'}
  },
  {
    path: '/live',
    component: LiveIndex,
    meta: { title: '直播'}
  },


  {
    path: '/test',
    component: TestIndex,
    meta: { title: '测试'}
  }

];

const router = new VueRouter({
  routes,
  mode: "history",
  linkActiveClass: 'active'  // 修改活跃属性名
})

export default router;
